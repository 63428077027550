import React, { useEffect } from 'react';
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import Header from 'components/DataAnalysis/Header';
import Footer from "components/Saas/Footer";
import Navbar from "components/Navbars/ITCreativeNav";
import Images from 'components/Style2/Careers/Images';

const HomeDataAnalysis = () => {
  useEffect(() => {
    document.body.classList.add('home-style-8');
    return () => document.body.classList.remove('home-style-8');
  }, []);

  return (
    <MainLayout>
        <Navbar />
      <Header />
      <main>       
        <Images />
      </main>
      <Footer />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
     <title> Look at the Beautiful Memories We Created</title>
<meta name="description" content="Join us on a visual journey through our history. Explore our photo gallery and see the impact we've made.
" /> 
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default HomeDataAnalysis;